import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Button, Divider, Grid, Icon, Table, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import { TicketWorkflowSteps } from "../../../app/models/enums";
import PotentialCustomerMatchingMessage from "../../customers/PotentialCustomerMatchingMessage";
import SummaryButton from "./SummaryButton";
import { MiscellaneousCharge } from "../../../app/models/miscellaneousCharges";

interface Props {
  autoCapitalizeFirstLetter: boolean;
  alwaysMiscellaneousChargeItems: MiscellaneousCharge[];
}

export default observer(function JobType({
  autoCapitalizeFirstLetter,
  alwaysMiscellaneousChargeItems,
}: Props) {
  const { t } = useTranslation(["common", "translation"]);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { complete } = useParams<{ complete: string }>();

  const { ticketSessionStore, jobLaborStore, vehicleStore, customerStore } =
    useStore();

  const {
    selectedTicketSession,
    updateTicketSession,
    selectedJobLabor,
    updatesJobLabor,
    setSelectedJobLaborJobType,
  } = ticketSessionStore;
  const { loadJobTypeList, jobTypeList, handleImage } = jobLaborStore;

  const { selectedVehicle } = vehicleStore;
  const { selectedCustomer } = customerStore;

  useEffect(() => {
    if (selectedJobLabor?.job && selectedJobLabor.job.jobCategoryId) {
      loadJobTypeList(selectedJobLabor.job.jobCategoryId);
    }
  }, [loadJobTypeList, selectedJobLabor?.job]);

  function handleJobType(jobTypeId: number, jobType: string) {
    if (selectedJobLabor?.job) {
      setSelectedJobLaborJobType(jobTypeId, jobType);
      updatesJobLabor(selectedJobLabor);

      if (selectedTicketSession && id && selectedJobLabor) {
        updateTicketSession(
          TicketWorkflowSteps.JobType,
          id,
          selectedVehicle,
          selectedCustomer
        ).then(() => {
          navigate(`/ticket/job/${id}/${complete}`);
        });
      }
    }
  }

  return (
    <Grid stackable>
      <Grid.Column width={11}>
        <PotentialCustomerMatchingMessage
          workflowStep={TicketWorkflowSteps.Finalize}
        />
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column verticalAlign="bottom">
              <PageHeader
                type="h1"
                header={t("job_type", { ns: "tickets" }).toString()}
                className="modal-text-color"
                textAlign="left"
                divider={false}
                addTitle={true}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="bottom">
              <Button
                floated="right"
                className="no_padding_image_buttons"
                type="button"
                onClick={() => {
                  navigate(`/ticket/jobCategory/${id}/${complete}`);
                }}
              >
                <Image
                  src={
                    selectedJobLabor?.job &&
                    selectedJobLabor.job.jobCategoryId &&
                    handleImage(selectedJobLabor.job.jobCategoryId)
                  }
                />
              </Button>
              <SummaryButton
                id={id ?? ""}
                selectedVehicle={selectedVehicle}
                selectedCustomer={selectedCustomer}
                completed={complete ?? "false"}
                alwaysMiscellaneousChargeItems={alwaysMiscellaneousChargeItems}
              />
              <Button
                floated="right"
                onClick={() => {
                  navigate(`/ticket/jobCategory/${id}/${complete}`);
                }}
              >
                <Icon name="caret left" />
                {t("back", { ns: "tickets" })}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Grid>
          <Grid.Row>
            <Table striped singleLine>
              <Table.Body>
                {jobTypeList &&
                  jobTypeList.length > 0 &&
                  jobTypeList.map((jobType, index) => (
                    <Table.Row key={`jobType${jobType.value}`}>
                      <Table.Cell>
                        <strong>
                          <Link
                            onClick={() => {
                              if (jobType.value && jobType.text)
                                handleJobType(
                                  Number(jobType.value),
                                  jobType.text.toString()
                                );
                            }}
                            to={""}
                          >
                            {jobType.text}
                          </Link>
                        </strong>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column width={5}>
        <Grid>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Sidebar
              workflowStep={TicketWorkflowSteps.JobType}
              autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
            />
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  );
});
