// utils/gtag.ts
declare global {
  interface Window {
    gtag: (action: string, gaTrackingId: string, options?: any) => void;
  }
}

// Function to send a custom event
export const event = ( action: string , sendTo:string ) => {
  if (typeof window !== "undefined") {
    window.gtag("event", action, {
      send_to: sendTo,
    });
  }
};
