import { Application, PaymentStatus, SubscriptionType, TransactionType } from "./enums";
export interface ClientSubscriptions {
  subscriptionId?: string;
  dowellSystemApplication?: number;
  term?: number;
  userCount?: number;
  startDate?: Date;
  endDate?: Date;
  isTrial: boolean;

  firstName?: string;
  lastName?: string;
  clientName?: string;
  email?: string;

  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;

  billingPhone?: string;
  creditCardNumber?: string;
  expirationMonth?: string;
  expirationYear?: string;
  securityCode?: string;

  agreeTerms: boolean;
  agreeSubscription: boolean;
  useCreditCardOnFile: boolean;
  error?: string;
}

export class ClientSubscriptions implements ClientSubscriptions {
  constructor(init?: ClientSubscriptionsFormValues) {
    if (init) {
      if(init.subscriptionId)
        this.subscriptionId = init.subscriptionId;
      this.dowellSystemApplication = Number(init.dowellSystemApplication);
      this.term = Number(init.term);
      this.userCount = Number(init.userCount);
      this.startDate = init.startDate ? new Date(init.startDate) : undefined;
      this.endDate = init.endDate ? new Date(init.endDate) : undefined;
      this.isTrial = init.isTrial;

      this.firstName = init.firstName;
      this.lastName = init.lastName;
      this.clientName = init.clientName;
      this.email = init.email;

      this.addressLine1 = init.addressLine1;
      this.addressLine2 = init.addressLine2;
      this.city = init.city;
      this.state = init.state;
      this.postalCode = init.postalCode;
      this.country = init.country;

      this.billingPhone = init.billingPhone;
      this.creditCardNumber = init.creditCardNumber;
      this.expirationMonth = init.expirationMonth;
      this.expirationYear = init.expirationYear;
      this.securityCode = init.securityCode;

      this.agreeTerms = init.agreeTerms;
      this.agreeSubscription = init.agreeSubscription;
      this.useCreditCardOnFile = init.useCreditCardOnFile;
      this.error = init.error;
    }
  }
}

export class ClientSubscriptionsFormValues {
  subscriptionId?: string = undefined;
  dowellSystemApplication?: number = undefined;
  term?: number = undefined;
  userCount?: number = undefined;
  startDate?: Date = undefined;
  endDate?: Date = undefined;
  isTrial: boolean = false;

  firstName?: string = undefined;
  lastName?: string = undefined;
  clientName?: string = undefined;
  email?: string = undefined;

  addressLine1?: string = undefined;
  addressLine2?: string = undefined;
  city?: string = undefined;
  state?: string = undefined;
  postalCode?: string = undefined;
  country?: string = undefined;

  billingPhone?: string = undefined;
  creditCardNumber?: string = undefined;
  expirationMonth?: string = undefined;
  expirationYear?: string = undefined;
  securityCode?: string = undefined;

  agreeTerms: boolean = false;
  agreeSubscription: boolean = false;
  useCreditCardOnFile: boolean = false;
  error?: string = undefined;

  constructor(clientSubscriptionFormValues?: ClientSubscriptionsFormValues) {
    if (clientSubscriptionFormValues) {
      if(clientSubscriptionFormValues.subscriptionId)
        this.subscriptionId = clientSubscriptionFormValues.subscriptionId;
      this.dowellSystemApplication = Number(
        clientSubscriptionFormValues.dowellSystemApplication
      );
      this.term = Number(clientSubscriptionFormValues.term);
      this.userCount = Number(clientSubscriptionFormValues.userCount);
      this.startDate = clientSubscriptionFormValues.startDate ? new Date(clientSubscriptionFormValues.startDate) : undefined;
      this.endDate = clientSubscriptionFormValues.endDate ?new Date(clientSubscriptionFormValues.endDate) : undefined;
      this.isTrial = clientSubscriptionFormValues.isTrial;

      this.firstName = clientSubscriptionFormValues.firstName;
      this.lastName = clientSubscriptionFormValues.lastName;
      this.clientName = clientSubscriptionFormValues.clientName;
      this.email = clientSubscriptionFormValues.email;

      this.addressLine1 = clientSubscriptionFormValues.addressLine1;
      this.addressLine2 = clientSubscriptionFormValues.addressLine2;
      this.city = clientSubscriptionFormValues.city;
      this.state = clientSubscriptionFormValues.state;
      this.postalCode = clientSubscriptionFormValues.postalCode;
      this.country = clientSubscriptionFormValues.country;

      this.billingPhone = clientSubscriptionFormValues.billingPhone;
      this.creditCardNumber = clientSubscriptionFormValues.creditCardNumber;
      this.expirationMonth = clientSubscriptionFormValues.expirationMonth;
      this.expirationYear = clientSubscriptionFormValues.expirationYear;
      this.securityCode = clientSubscriptionFormValues.securityCode;

      this.agreeTerms = clientSubscriptionFormValues.agreeTerms;
      this.agreeSubscription = clientSubscriptionFormValues.agreeSubscription;
      this.error = clientSubscriptionFormValues.error;
    }
  }
}

export interface ClientSubscriptionStatus {
  lastPaymentDate?: Date;
  application: Application;
  activeUsers: number;
  subscriptionUsers: number;
  lastPaymentAmount?: number;
  renewalApplication?: Application;
  renewalDate?: Date;
  renewalPaymentAmount: number;
  renewalUsers: number;
  renewalPeriod: number;
  isTrial: boolean;
  isActive: boolean;
  isCancelled: boolean;
  isExtension:boolean;
  cancelledDate?: Date;
  subscriptionStart: Date;
  term: number;
}

export interface ClientPaymentHistory {
  clientSubscriptionId?: string;
  clientBillingId?: string;
  createdDate?: Date;
  amount?: number;
  status: PaymentStatus;
  creditCardLast4: string;
  subscriptionType: SubscriptionType;
  transactionType: TransactionType;
}
