import React from "react";
import { Card, Grid, Icon, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../layout/PageHeader";
import modalStore from "../../stores/modalStore";
import { useStore } from "../../stores/store";

interface Props {
  name: string;
  help: string;
  fluid?: boolean;
}

export default function MyHelpText(props: Readonly<Props>) {
  const { t } = useTranslation(["common", "help"]);
  let image1: string = `${props.help}.image1`;
  let image2: string = `${props.help}.image2`;

  const { modalStore } = useStore();

  return (
    <Card fluid={props.fluid} className="bordernone">
      <Card.Header>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
            <PageHeader
              type="h3"
              header={t(`${props.help}.header`, { ns: "help" })}
              divider={false}
              addTitle={false}
            />
            </Grid.Column>
            <Grid.Column textAlign="right">
            <Icon link name="x" onClick={() => modalStore.closeModal()}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Header>
      <Card.Content className="help-text linebreaks">
        {t(`${props.help}.content1`, { ns: "help" })}
        {t(`${props.help}.image1`, { ns: "help" }) !==
          `${props.help}.image1` && (
          <Image
            size="massive"
            src={`/assets/help/${t(image1, { ns: "help" })}`}
          ></Image>
        )}
        {t(`${props.help}.content21`, { ns: "help" }) !==
          `${props.help}.content21` && (
          <>{t(`${props.help}.content21`, { ns: "help" })}</>
        )}
        {t(`${props.help}.image2`, { ns: "help" }) !==
          `${props.help}.image2` && (
          <Image
            fluid
            src={`/assets/help/${t(image2, { ns: "help" })}`}
          ></Image>
        )}
      </Card.Content>
    </Card>
  );
}
