import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { ReportInput, TechnicianReport } from "../../models/reports";
import { format } from "date-fns";
import { Pagination, PagingParams } from "../../models/pagination";
import { DateRangeOption, ReportType } from "../../models/enums";
import i18n from "../../common/i18n/i18n";
import { sortingStrings } from "../../common/util/functions";

export default class TechnicianReportStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.technicianRegistry.clear();
        this.loadTechnicianReport();
      }
    );
  }

  loadingInitial = false;
  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  reportCriteria: ReportInput = new ReportInput({
    dateRange: DateRangeOption.Today,
    startDate: new Date(),
    endDate: new Date(),
    reportType: ReportType.Summary,
    filter: "assigned",
  });

  technicianRegistry = new Map<Date, TechnicianReport>();

  resetTechnicianRegistry = () => {
    this.technicianRegistry.clear();
  };

  loadTechnicianReport = async () => {
    this.loadingInitial = true;
    try {
      this.technicianRegistry.clear();
      const result = await agent.Reports.getTechnicianReport(this.axiosParams);
      runInAction(() => {
        result.forEach((technician) => {
          this.setTechnicianReport(technician);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setTechnicianReport = (technician: TechnicianReport) => {
    if (technician.date) technician.date = new Date(technician.date);
    this.technicianRegistry.set(technician.date ?? new Date(), technician);
  };

  get getTechnicianReport() {
    return Array.from(this.technicianRegistry.values()).sort((a, b) => {
      let temp: number =
        sortingStrings(
          a.lastName?.toLowerCase() ?? "",
          b.lastName?.toLowerCase() ?? ""
        ) ?? 0;

      if (temp === 0)
        temp =
          sortingStrings(
            a.firstName?.toLowerCase() ?? "",
            b.firstName?.toLowerCase() ?? ""
          ) ?? 0;

      return temp;
    });
  }

  pagingParams = new PagingParams();
  pagination: Pagination | null = null;
  predicate = new Map().set("all", true);

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "StartDate" || key === "EndDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (
    startDate?: Date,
    endDate?: Date,
    type?: ReportType,
    filter?: string
  ) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };
    resetPredicate();
    if (startDate) this.predicate.set("StartDate", startDate);
    if (endDate) this.predicate.set("EndDate", endDate);
    if (type) this.predicate.set("Type", type);
    if (filter) this.predicate.set("Filter", filter);
  };

  setReportCriteria = (values: ReportInput) => {
    this.reportCriteria = new ReportInput(values);
  };

  get groupedTechnicianReport() {
    return Object.entries(
      this.getTechnicianReport.reduce((groups, technician) => {
        const name = `${
          technician.lastName
            ? `${technician.lastName}${
                technician.lastName &&
                technician.firstName &&
                `, ${technician.firstName}`
              }`
            : i18n.t("unassigned", {
                ns: "tickets",
              })
        }`;
        groups[name] = groups[name]
          ? [...groups[name], technician]
          : [technician];
        return groups;
      }, {} as { [key: string]: TechnicianReport[] })
    );
  }

  groupTechniciansByDate = (data: TechnicianReport[]) => {
    return Object.entries(
      data.reduce((groups, technician) => {

        groups[format(new Date(technician.date!), "MMMM yyyy")] = groups[
          format(new Date(technician.date!), "MMMM yyyy")
        ]
          ? [...groups[format(new Date(technician.date!), "MMMM yyyy")], technician]
          : [technician];
        return groups;
      }, {} as { [key: string]: TechnicianReport[] })
    );
  };

  calculateTotals = (data: TechnicianReport[]) => {
    const summary = data.reduce(
      (accumulator: TechnicianReport, item: TechnicianReport) => {
        accumulator.quantity += item.quantity;
        accumulator.rate += item.rate;
        accumulator.subTotal += item.subTotal;
        return accumulator;
      },
      {
        quantity: 0,
        rate: 0,
        subTotal: 0,
      }
    );

    return summary;
  };
}
